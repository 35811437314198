footer{
    background-color: black;
    padding: 50px 0px;
}
.footerlogo img{
    width: 100%;
    padding: 20px;
    background-color: white;
}
.textfooter{
    color: white;
}
.display-flex{
    display: flex;
}
.homecall img{
    width: 28px;
    background-color: white;
    padding: 2px;
    border-radius: 50%;
}
.textimg{
    display: flex;
    align-items: center;
}
.changelink{
    list-style-type: none;
    text-decoration: none;
}
.textimg h6{
    color: white;
    margin-bottom: 0px;
    margin-left: 10px;
}
.paddimg img{
    margin: 0px 3px;
}
.jucticenter{
    display: flex;
    justify-content: center;
}
.secexplotitt{
    display: flex;
    width: 100%;
    justify-content: center;
    color: white;
    margin-bottom: 10px;
}
.secexplotitt h3::after{
    align-items: center;
    justify-content: center;
    background-color: white;
    display: flex;
    margin-top: 10px;
    content: ' ';
    display: flex;
    height: 3px;
    width: 60%;
}
.foseli{
    text-decoration: none;
    color: white;
}
.foseli h6{
    margin-bottom: 0px;
}
.footerul{
    list-style-type: circle;
}
.footerul li{
    color: white;
    margin: 12px 0px;
}
.footerul li::marker{
    color: white;
    height: 10px;
    width: 10px;
}
.listnone{
    list-style-type: none;
    margin-left: 0px;
    padding-left: 0px;
}
.footertext{
    margin-bottom: 10px;
}
.footertext h6{
    line-height: 24px;
}
.footalik{
    text-decoration: none;
    display: flex;
    justify-content: center;
}
.footceter{
    display: flex;
    align-items: center;
    padding: 10px 0px;
    justify-content: center;
}
.mainnamespin{
    padding: 5px;
    height: 30px;
    width: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #90c0ff;
    border-radius: 50%;
}
.mainnamespin img{
    width: 22px;
}
.headicopad{
    background-color: white;
    margin: 5px;
}
.b7cdd0{
    background-color: #3086f7;
}
.appaddlink{
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    margin-left: 0px;
    color: white;
    text-decoration: none;
}
.footceter{
    display: flex;
    align-items: center;
    padding: 10px 0px;
    justify-content: center;
}
.footalik h6{
    display: flex;
    align-items: center;
    text-align: center;
    margin: 0px;
    padding: 0px;
    margin-left: 10px;
}
@media (max-width:500px){
    .footerdisnone h6{
        display: none;
    }
}