.serverssss{
    margin: 0px 0px;
    max-width: 500px;
    padding:30px 30px;
    background-color: #00acec;
    border-radius: 40px;
    display: block;
}
.inputers{
    background-color: white;
    height: 55px;
    width: 100%;
    border: 0px;
    margin-bottom: 26px;
    border-radius: 20px;
}
.inputerss{
    background-color: white;
    height: 150px;
    width: 100%;
    border: 0px;
    margin-bottom: 30px;
    border-radius: 20px;
}
.submiter{
    padding: 8px 30px;
    border-radius: 40px;
    font-size: 22px;
    font-weight: 600;
    background-color: #ffa200;
    border: 0px;
    color: white;
}
.centifier{
    display: flex;
    align-items: center;
    justify-content: center;
}