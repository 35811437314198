.logobox img{
    width: 200px;
}
.headertop{
    border-bottom: 0.1px solid #00acec;
}
.linkbox{
    display: flex;
    list-style-type: none;
}
.outerfirst{
    background-color: #00acec;
}
.headersec{
    display: flex;
    padding: 5px 0px;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}
.viner{
    position: relative;
}
.headerlink{
    position: relative;
    text-decoration: none;
    color: black;
    font-weight: 500;
    padding: 10px 10px;
}
.linkappiont{
    display: flex;
    text-align: center;
    text-decoration: none;
    color: white;
    padding: 10px 20px 12px;
    background-color: #00acec;
    align-items: center;
    border-radius: 10px;
    font-weight: 500;
}
.homecall img{
    width: 28px;
    background-color: white;
    padding: 2px;
    border-radius: 50%;
}
.textimg{
    display: flex;
    align-items: center;
}
.textimg h6{
    color: white;
    margin-bottom: 0px;
    margin-left: 10px;
}
.paddimg img{
    margin: 0px 3px;
}
.display-flex{
    display: flex;
}
.menu img{
    width: 30px;
}
.menuouter{
    background-color: white;
    border: 0px;
    display: none;
}
.imginlogoe{
    margin-left: 20px;
    display: flex;
}
.imginlogoe h6{
    margin-bottom: 0px;
    display: flex;
    align-items: center;
}
.uliheder{
    list-style-type: none;
    z-index: 1;
    position: absolute;
    display: block;
}
.kite{
    text-decoration: none;
    color: black;
    padding: 0px;
    z-index: 333;
}
.subheder{
    margin-top: 20px;
    padding: 10px 20px;
    background-color: white;
    z-index: 1;
}
.linker{
    background-color: white;
    text-decoration: none;
    color: black;
    font-size: 16px;
    font-weight: 600;
}
.uliheder{
    display: none;
}
.viner:hover .uliheder{
    display: block;
}
.linkera{
    width: 200px;
    padding: 15px 20px;
    background-color: white;
    text-decoration: none;
    color: black;
    border-bottom: 5px solid #00acec;
    border-left: 1px solid #00acec;
    font-size: 16px;
    font-weight: 600;
}
.imaging{
    background-image: url(../../../public/photo/imgtwo.jpg);
}
.fixheder{
    position: fixed;
    width: 100%;
    background-color: white;
    z-index: 23;
}
@media (max-width:1200px){
    .linkappiontd{
        display: none;
    }
}
@media (max-width:992px){
    .linkbox{
        display: none;
    }
    .menuouter{
        display: block;
    }
}
@media (max-width:768px){
    .imginlogoe{
        display: none;
    }
}
@media (max-width:450px){
    .firsticon:nth-child(3){
        display: none;
    }
    /* .firsticon:nth-child(4){
        display: none;
    } */
    /* .firstphone h6:nth-child(2){
        display: none;
    } */
    .logobox img{
        width: 200px;
    }
}