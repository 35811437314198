.backerfixs{
    background-attachment: fixed;
    padding: 50px 0px;
    display: flex;
    align-items: center;
    background-position: center;
    background-size: cover;
    background-image:linear-gradient(#00adec79,#00adec85),url(../../../public/photo/bannerslider.jpg);
}
.serviceimg img{
    width: 100%;
}
.moniter{
    text-decoration: none;
    text-align: center;
}
.movingser{
    background-color: white;
    text-decoration: none;
}
.movingser h6{
    color: black;
    text-decoration: none;
}
.movingser h5{
    color: black;
    text-decoration: none;
}
.innertexth{
    position: relative;
}
.rowhome{
    display: flex;
    width: 100%;
}
.rowhomehi{
    width: 100%;
    overflow: hidden;
}
.innertexth h6{
    color: white;
}
.displayflex{
    display: flex;
}
.fixrowser{
    margin-bottom: 30px;
    color: white;
}
.contactfix{
    text-decoration: none;
    background-color: white;
    display: flex;
    color: rgb(9, 9, 146);
    width: 150px;
    align-items: end;
    justify-content: center;
    margin-top: 40px;
}
.contactfix h6{
    color: rgb(9, 9, 146);
    margin-bottom: 0px;
    display: flex;
    padding: 10px 20px;
}
@media (max-width:580px){
    .innertexth{
        margin-top: 20px;
    }
}
.filtersc{
    display: flex;
    align-items: center;
    justify-content: center;
}
.servers{
    margin: 70px 0px;
    width: 350px;
    padding:60px 40px;
    background-color: #00a0e4;
    box-shadow: 5px 5px 5px #0168949e;
    border-radius: 40px;
}
.inputerssr{
    background-color: white;
    height: 35px;
    width: 100%;
    margin-bottom: 26px;
    border-radius: 20px;
}
.inputersssr{
    background-color: white;
    height: 80px;
    width: 100%;
    margin-bottom: 20px;
    border-radius: 20px;
}
.submitersr{
    padding: 15px 30px;
    border-radius: 40px;
    font-size: 22px;
    font-weight: 600;
    background-color: rgb(232, 47, 69);
    border: 0px;
    color: white;
}
.titless{
    display: flex;
    width: 100%;
    color: white;
    padding: 30px 0px;
    align-items: center;
    text-align: center;
    height: auto;
}
.titless h1{
    display: flex;
    text-align: center;
    justify-content: center;
    line-height: 48px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    margin-bottom: 20px;
    font-weight: 800;
    text-shadow: 3px 3px 3px #0a19544e;
}
.allnini{
    display: flex;
    align-items: center;
}
@media(max-width:992px){
    .servers{
        margin-bottom: 0px;
    }
}