.widthcan img{
    width: 40px;
}
.namesmob{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
}
.linerline{
    list-style-type: none;
}
.chancemob{
    background-color: #00acec;
}
.posimob{
    background-color: rgba(216, 216, 216, 0.874);
    position: fixed;
    z-index: 9;
    width: 100%;
    display: none;
}
.linerstexew h1{
    color: #00acec;
}
@media(max-width:1200px){
    .posimob{
        display: block;
    }
}