/* contact box */
.conconbox{
    padding: 8px 20px;
    color: #944f9f;
    background-color: white;
    border: 2px solid #944f9f;
}
.condiv a{
    text-decoration: none;
}
.conconbox h5{
    margin-bottom: 0px;
}
.conbox{
    display: flex;
    background-color: #1bc0fc;
    justify-content: space-around;
    padding: 20px;
}
.imgcont{
    display: flex;
}
.context{
    display: flex;
    align-items: center;
    color: white;
}
.context h6{
    margin-bottom: 0px;
}
.imgconbox img{
    width: 70px;
    padding: 8px;
    background-color: white;
    border-radius: 50%;
    border: 4px solid #944f9f;
}
.imgconbox{
    margin-right: 20px;
}
.condiv{
    display: flex;
    align-items: center;
}
@media (max-width:992px){
    .conbox{
        display: block;
    }
    .imgcont{
        justify-content: center;
    }
    .condiv{
        margin-top: 10px;
        justify-content: center;
    }
}
@media (max-width:768px){
    .imgcont{
        display: block;
    }
    .imgconbox{
        display: flex;
        justify-content: center;
        margin: 0px;
        margin-bottom: 20px;
    }
    .condiv{
        margin-top: 20px;
    }
    .conbr{
        display: none;
    }
    .context{
        display: flex;
        text-align: center;
    }
}