.biggersmall{
    background-image:linear-gradient(#00adec79,#00adec85),url(../../../public/photo/bannerout.jpg);
    background-size: cover;
    display: flex;
    background-attachment: scroll;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 30px 20px;
}
.numberslink h4{
    color: white;
}
.numberslink h6{
    color: white;
}
.bookappoint{
    background-color: white;
    color: #073f87;
    font-weight: 600;
    padding: 8px 12px;
    border: 0px;
    margin-top: 10px;
}
.containe{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.bgvision{
    background-color: #f0f2f7;
}
.innnerh1 h5{
    color: #073f87;
}
.innnerh1 h1{
    color: #ffa200;
    font-size: 60PX;
}
@media(max-width:992px){
    .containe{
        margin-top: 20px;
    }
}