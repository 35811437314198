.backerfix{
    height: 500px;
    background-attachment: fixed;
    display: flex;
    align-items: center;
    background-position: center;
    background-size: cover;
    background-image:linear-gradient(#00adec79,#00adec85),url(../../../public/photo/servicebanneer.jpg);
}
.textinbackfix h3{
    color: white;
    text-align: center;
    align-items: center;
    font-size: 32px;
}
.textinbackfix p{
    color: white;
    text-align: center;
    align-items: center;
    font-size: 14px;
}
.linkinboxfix{
    color: white;
    text-decoration: none;
    align-items: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.linkinboxfix h6{
    display: flex;
    margin-top: 8px;
    align-items: center;
    text-align: center;
    justify-content: center;
    background-color:#30327d ;
    width: 150px;
    height: 40px;
    border-radius: 5px;
    border: 2px solid white;
}