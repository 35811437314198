.addinfobox{
    padding: 8px;
}
.innerinfobox{
    height: 100%;
}
.textboxin{
    padding: 0px 20px;
}
.timebox{
    display: flex;
    justify-content: space-between;
}
.timeinfo h5{
    margin-bottom: 8px;
    text-align: center;
    color: rgb(0, 0, 162);
}
.timebox p{
    margin-bottom: 8px;
}
/* .adddbox{
    margin-top: -70px;
} */
.trangle{
    width: 19px;
    display: flex;
    border-top: 15px solid white;
    border-left: 15px solid white;
    border-bottom:15px solid white;
    border-right: 15px solid white;
    left: -20px;
    top: 20px;
}
.addresicon img{
    width: 70px;
    background-color: white;
    margin: 15px 0px;
    padding: 5px;
    border-radius: 50%;
}
.addresicon{
    display: flex;
    justify-content: left;
}
.addbar{
    width: 60%;
    height: 6px;
    transition: 2s;
}
.infoapp{
    width: 100%;
    color: white;
    text-align: center;
    padding: 5px;
}
.infoapp h6{
    margin-bottom: 3px;
}
.addinfohover:hover .addhover{
    width: 100%;
}
.textertest{
    text-align: center;
}
.backred{
    background: linear-gradient(rgba(255, 255, 255, 0.195),#17b3ec);
    background-color: #0093c93d;
    border-radius: 30px;
    box-shadow: 8px 8px 8px #0093c986;
}
.timeinfo h6{
    color: white;
    text-align: left;
    line-height: 22px;
}
.biggy{
    display: block;
}
.smaller{
    display: none;
}
@media (max-width:768px){
    .adddbox{
        margin-top: 0px;
    }
    .biggy{
        display: none;
    }
    .smaller{
        display: block;
    }
}
@media (max-width:340px){
    .textboxin{
        padding: 0px 5px;
    }
}
@media (max-width:300px){
    .textboxin{
        padding: 0px 2px;
    }
}