.title:nth-child(1){
    color: rgb(0, 0, 162);
    text-align: center;
}
.mbottommer{
    margin-top: 70px;
    margin-bottom: 70px;
}
.informationonp{
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
    color: grey;
}
.homecalla img{
    width: 22px;
    background-color: white;
    padding: 2px;
    border-radius: 50%;
}
.stylelink{
    display: flex;
    margin-left: 10px;
    align-items: center;
}
.stylelink h6{
    margin-bottom: 0px;
    margin-left: 10px;
    color: grey;
    border: 0px;
    width: 100%;
    padding: 12px 0px;
    border-bottom: 2px solid gray;
    border-style: dotted;
}
.designtitle{
    text-align: center;
    padding: 14px 0px;
    background-color: rgb(0, 0, 162);
    color: white;
}
.designtitle h3{
    margin-bottom: 4px;
} 
.designtitle h6{
    margin-bottom: 0px;
}
.bodyofpack{
    border: 2px solid rgb(0, 0, 162);
    border-style: dotted;
}
.topicpack{
    background-color: rgb(251, 251, 251);
}