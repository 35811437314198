.reviewimg img{
    width: 100%;
}
.reviewimg{
    position: absolute;
}
.reviewimga{
    display: flex;
    justify-content: center;
}
.centerdis{
    display: flex;
    align-items: center;
    justify-content: center;
}
.textcenter{
    display: flex;
    text-align: center;
    justify-content: center;
}
.ussersph{
    width: 100px;
    height: 100px;
    border-radius: 20px;
    margin-bottom: 20px;
    border: 0px;
    background-image: url(../../../public/photo/user.png);
    position: relative;
    z-index: 1;
}
.col-12er{
    display: flex;
    justify-self: center;
}
.mainreview{
    display: flex;
    justify-content: left;
}
.usser{
    border-radius: 20px;
    width: 60px;
    margin-right: 10px;
}
.textinre{
    width: 100%;
    height: 30px;
    margin-bottom: 10px;
    margin-top: 10px;
}
.reviewinter{
    padding: 30px;
    padding-top: 40px;
    padding-bottom:40px;
    background-color: #30327d;
    margin-left: 70px;
    position: relative;
    z-index: 1;
    margin-top: 35%;
}
.textarere{
    height: 100px;
    width: 100%;
    margin-top: 10px;
}
.frfeviewuser{
    display: flex;
    justify-content: center;
}
.centerul{
    justify-content: center;
    margin-bottom: 10px;
}
.startbox{
    display: flex;
    list-style-type: none;
    padding-left: 0px;
    margin-bottom: 0px;
}
.centerreviewt h5{
    color: white;
}
.star{
    width: 20px;
    margin: 5px;
}
.centerreviewt{
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}
.innfoinrev h5{
    color: rgb(0, 0, 162);
}
.submiter{
    padding: 8px 20px;
    border: 2px solid black;
    font-size: 16px;
}
.submiter h6{
    color: black;
    font-size: 16px;
    margin-bottom: 0px;
}
.submiterdiv{
    display: flex;
    margin-top: 10px;
    justify-content: space-between;
}
@media (max-width:1200px){
    .reviewinter{
        margin-left: 30px;
        margin-top: 50%;
    }
}
@media (max-width:992px){
    .reviewinter{
        margin-left: 30px;
        margin-top: 40%;
        margin-bottom: 20px;
    }
}
@media (max-width:768px){
    .reviewinter{
        margin-left: 10px;
        margin-top:20px;
        margin-bottom: 20px;
    }
}