.title:nth-child(1){
    color: rgb(0, 0, 162);
    text-align: center;
}
.imginser{
    display: flex;
    align-items: center;
}
.imginsers{
    display: flex;
    align-items: center;
}
.imginser img{
    width: 60px;
    padding: 0px;
    background-color: white;
    border-radius: 50%;
    margin-right: 20px;
    border: 3px solid #0093c9;
}
.imginsers img{
    width: 60px;
    padding: 0px;
    background-color: white;
    border-radius: 50%;
    margin-right: 20px;
    border: 3px solid gray;
}
.imginser h6{
    font-weight: 700;
    font-size: 18px;
}
.imginsers h6{
    font-weight: 700;
    font-size: 18px;
}
.imgslinks{
    padding: 10px 3px;
    display: flex;
    justify-content: space-between;
}
.imgslinks h6{
    margin-bottom: 0px;
    display: flex;
    align-items: center;
}
.mbottommer{
    margin-top: 70px;
    margin-bottom: 70px;
}
.lattersl h1{
    color: #00acec;
    text-shadow: 1px 1px 1px #00acec;
    border-right: 4px solid #0093c9;
}
.taier{
    display: flex;
    align-items: center;
}
.backcolors{
    background-color: #94e2ff;
    height: 100%;
    border-radius: 5px;
    border: 1px solid #80ddff;
    box-shadow: 5px 5px 5px #94e2ffa2;
}
.backcolorser{
    background-color: rgb(243, 243, 243);
    height: 100%;
    border-radius: 5px;
    border: 1px solid rgb(243, 243, 243);
    box-shadow: 5px 5px 5px rgba(128, 128, 128, 0.468);
}