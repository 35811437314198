.hospitalin img{
    width: 100%;
    padding: 0px 10%;
}
.testinhom h1{
    color: rgb(0, 0, 162);
    margin-bottom: 20px;
}
.testinhom{
    text-align: center;
    align-items: center;
}
.alianitecent{
    display: flex;
    align-items: center;
}
.alianitecent p{
    font-weight: 600;
    color: black;
}
.infoapper{
    text-decoration: none;
    display: flex;
    justify-content: center;
}
.infoapper h6{
    border: 3px solid #0093c9;
    width: 200px;
    padding: 13px 30px;
    color: #ffa200;
    text-decoration: none;
    font-weight: 900;
    display: flex;
    font-size: 22px;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    border-radius: 20px;
}
.backimhinho{
    height: 100%;
    width: 100%;
    background-image: linear-gradient( rgba(49, 142, 241, 0.248), rgba(49, 142, 241, 0.21)),url(../../../public/photo/backimgin.jpg);
    background-position: center;
    background-size: cover;
}
.bodiinfo{
    position: relative;
    z-index: 2;
    background-color: rgba(255, 255, 255, 0.462);
}
.displayercen{
    height: 100%;
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 40px 0px;
}
.whiteback{
    background-color: rgba(255, 255, 255, 0.255);
    position: absolute;
    z-index: 1;
}
.testinhom h1{
    color: #0093c9;
}
.item-center{
    height: 100%;
    align-items: center;
}
@media (max-width:1400px){
    .displayercen{
        padding: 30px 0px;
    }
}
@media (max-width:1300px){
    .testinhom p:nth-child(2){
        display: none;
    }
    .infoapper h6{
        margin-top: 5px;
    }
    .displayercen{
        padding: 30px 0px;
    }
    .hospitalin img{
        padding: 0px 5%;
    }
}
@media (max-width:992px){
    .displayercen{
        padding: 0px 0px;
        display: none;
    }
    .testinhom p:nth-child(2){
        display: block;
    }
    .padderbackim{
        background-image: linear-gradient( white, white),url(../../../public/photo/backimgin.jpg);
        background-position: center;
        background-size: cover;
    }
    .backimhinho{
        display: none;
    }
    .bodiinfo{
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .hospitalin{
        padding-top: 20px;
    }
}