
.banner{
    width: 100%;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}
.bannertextf{
    display: flex;
    color: white;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.599);
    text-decoration: none;
    text-align: center;
    justify-content: center;
    padding: 0px 20px;
}
.mainbannertext{
    text-shadow: 4px 4px 4px rgba(255, 255, 255, 0.293);
}
.aboutbanner{
    background-image: url(../../../public/photo/aboutbanner.jpg);
}
.contactbanner{
    background-image: url(../../../public/photo/contactbanner.jpg);
}
.backgrey{
    background-color: rgb(244, 244, 244);
}
.sonomammography{
    background-image: url(../../../public/photo/jhfbdfds.jpg);

}
.centers h5{
    display: flex;
    justify-content: center;
    text-align: center;
}
.p-222{
    padding: 20px 20px 0px 20px;
}
.ellnesscreenings{
    background-image: url(../../../public/photo/hellohyper.jpg);
}
.hiderss{
    height: 100%;
    background-color: #f1f7ff;
    border-radius: 10px;
    border: 1px solid #c7deff;
}
.laboratory{
    background-image: url(../../../public/photo/alala.jpg);
}
.lader{
    background-image: url(../../../public/photo/lab.jpg);

}
.higerli div img{
    border-radius: 10px;
}
.linetext h6{
    color: #2c5a9f;
}